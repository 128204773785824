<template>
  <Form
    :title="title"
    :hide-reset="edit"
    :loading="loading"
    @submit="onSubmit"
    @reset="onReset"
  >
    <h3 class="my-3">Informazioni Piano</h3>

    <v-row>
      <FormItem
        v-model="code"
        :readonly="edit"
        :rules="{ required: true }"
        type="text"
        name="Identificativo"
      />
      <FormItem v-model="name" rules="required" type="text" name="Nome Piano" />

      <FormItem v-model="description" name="Descrizione" />

      <FormItem
        v-model="active"
        rules="required"
        type="select"
        :values="[
          { value: true, text: 'Attiva' },
          { value: false, text: 'Disattiva' },
        ]"
        name="Stato Piano"
      />
    </v-row>
    <v-row>
      <FormProjects v-model="project_key" rules="required" name="Progetto" />
      <FormItem
        v-model="channel"
        type="select"
        rules="required"
        name="Canale"
        :values="['Diretta', 'DigitalStore', 'AppDirect']"
      />

      <FormItem
        v-model="auto_start"
        type="switch"
        name="Avvio Sottoscrizione Automatico"
        dense
      />

      <FormItem
        v-model="auto_renew"
        type="switch"
        name="Rinnovo Automatico"
        dense
      />
    </v-row>

    <h3 class="my-3">Durata e contenuti</h3>
    <v-row>
      <FormItem
        v-model="duration"
        type="number"
        name="Durata Sottoscrizione (gg)"
        rules="required|min_value:1"
        min="1"
      />
      <FormItem
        v-model="cycle"
        type="number"
        name="Durata Ciclo (gg)"
        rules="required|min_value:1"
        min="1"
      />
      <FormItem
        v-model="trial"
        type="number"
        name="Durata Trial (gg)"
        rules="required|min_value:0"
        min="0"
      />

      <FormItem
        v-model="billing_cycle"
        type="number"
        name="Ciclo Fatturazione"
        rules="required|min_value:1"
        min="1"
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="max_ticket_per_user"
        type="number"
        name="Max Ticket per Utente"
        rules="required|min_value:-1"
        min="-1"
      /><FormItem
        v-model="max_ticket_per_cycle"
        type="number"
        name="Max Ticket per Ciclo"
        rules="required|min_value:-1"
        min="-1"
      /><FormItem
        v-model="max_ticket_per_month"
        type="number"
        name="Max Ticket per Mese"
        rules="required|min_value:-1"
        min="-1"
      /><FormItem
        v-model="max_ticket_per_duration"
        type="number"
        name="Max Ticket per Durata"
        rules="required|min_value:-1"
        min="-1"
      />

      <FormItem
        v-model="max_devices"
        type="number"
        name="Max Dispositivi"
        rules="required|min_value:-1"
        min="-1"
      />
      <FormItem
        v-model="max_devices_per_user"
        type="number"
        name="Max Dispositivi per Utente"
        rules="required|min_value:-1"
        min="-1"
      />
    </v-row>

    <h3 class="my-3">Costi</h3>
    <v-row>
      <FormItem v-model="billable" type="switch" name="Da fatturare" dense />

      <FormItem
        v-model="cost_activation"
        type="number"
        name="Costo Attivazione"
        rules="required"
      />

      <FormItem
        v-model="cost_per_cycle"
        type="number"
        name="Costo per Ciclo"
        rules="required"
      />
    </v-row>

    <h3 class="my-3">Configurazione OTP</h3>
    <v-row>
      <FormItem
        v-model="otp_required"
        type="switch"
        name="Richiede OTP"
        dense
      />

      <FormItem
        v-model="otp_sender"
        name="Mittente Messaggio"
        clearable
        counter
        :rules="{ required: otp_required, min: 3, max: 11 }"
      />

      <FormItem
        v-model="otp_template"
        type="textarea"
        name="Testo Messaggio"
        counter
        rows="5"
        :rules="{ required: otp_required }"
      />
      <v-col>
        <strong>Campi disponibili</strong><br />
        <ul>
          <li><code>{ otp }</code> codice temporaneo</li>
          <li><code>{ nome }</code> nome cliente</li>
          <li><code>{ cognome }</code> cognome cliente</li>
          <li><code>{ piano }</code> nome piano</li>
          <li><code>{ attivazione }</code> costo attivazione</li>
          <li><code>{ costo }</code> costo ricorrente</li>
        </ul>
      </v-col>
    </v-row>

    <h3 class="my-3">Avanzate</h3>
    <v-row>
      <v-col>
        <vue-json-editor
          v-model="extra"
          :show-btns="false"
          :expanded-on-start="true"
          mode="code"
          :modes="['code', 'tree']"
          lang="it"
        ></vue-json-editor>
      </v-col>
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState } from 'vuex'
import vueJsonEditor from 'vue-json-editor'
import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormProjects from '@components/forms/FormProjects.vue'

const { mapFields } = createHelpers({
  getterType: 'plans/getCurrent',
  mutationType: 'plans/SET_CURRENT_FIELDS',
})

export default {
  name: 'PlansForm',
  components: {
    FormProjects,
    FormItem,
    Form,
    vueJsonEditor,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
  },
  data: () => ({
    statuses: [],
  }),
  computed: {
    ...mapFields([
      'code',
      'name',
      'description',
      'channel',
      'active',
      'cycle',
      'duration',
      'trial',
      'billable',
      'billing_cycle',
      'max_ticket_per_user',
      'max_ticket_per_cycle',
      'max_ticket_per_month',
      'max_ticket_per_duration',
      'max_devices',
      'max_devices_per_user',
      'devices_types',
      'cost_activation',
      'cost_per_cycle',
      'auto_start',
      'auto_renew',
      'project_key',
      'extra',
      'otp_required',
      'otp_sender',
      'otp_template',
    ]),
    ...mapState('plans', {
      id: (state) => state.current.id,
      types: (state) => state.types,
      loading: (state) => state.loading,
    }),
  },
  async mounted() {},
  methods: {
    ...mapMutations('plans', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('plans', ['create', 'update']),
    async onSubmit() {
      this.$emit('submit')

      if (!this.edit) {
        await this.create()
      } else {
        await this.update()
      }

      this.$emit('submitted', this.id)
    },
    onReset() {
      this.reset()
      this.$emit('resetted')
    },
  },
}
</script>

<style scoped></style>
